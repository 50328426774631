import styled from "styled-components";

const RoadmapInnerWrapper = styled.div`
  padding: 90px 0 30px;
  position: relative;

@media only screen and (min-width: 992px) {
  padding: 180px 0 50px;
}

.heading {
  padding-bottom: 80px;
}

.react-tabs {
  &__tab-list {
    background: rgba(0, 0, 0, 0.2);
    font-weight: 400;
    border: none;
    border-radius: 6px;
    color: #fff;
    display: flex;
    justify-content: space-between;

  }

  &__tab {
    padding: 15px 5px;
    margin: 0 10px;
    transition: color 0.3s;
    font-size: 14px;

    @media only screen and (min-width: 576px) {
      padding: 20px 10px;
      font-size: 20px;
    }

    @media only screen and (min-width: 768px) {
    padding: 25px 15px;
    font-size: 30px;
    }

    @media only screen and (min-width: 1200px) {
    padding: 30px 20px;
    font-size: 40px;
    
    }

    &:hover {
      color: #ffc500;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #ffc500;
      transform: scaleX(0);
      transition: transform 0.3s;
    }

    &--selected {
      color: #ffc500;
      background: transparent;
      border-color: transparent;
      box-shadow: none;

      &::after {
        transform: scaleX(1);
      }
    }
  }
}
.roadmap {
  &__container {
    position: relative;
    margin-top: 50px;
    @media only screen and (min-width: 992px) {

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 1px;
      height: 100%;
      background-color: #ffc500;
      z-index: -1;
    }

  }
  }

  &__panel-title {
    display: none;
    text-align: center;
    padding: 40px 0 ;
    background-color: #003166;
    font-size: 60px;
    font-weight: 400;

    @media only screen and (min-width: 992px) {
      display:block
    }
    
  }

  &__quartal {


    @media only screen and (min-width: 992px) {
   

      &:nth-child(odd) {
          text-align: right;


        .roadmap__quartal--circle {
          right: -15px;
        transform: translate(50%, -50%);
        }

        .roadmap__quartal--itemName {
          order: 2;
        }
        .roadmap__quartal--itemType {
          order: 1;
        }

        .roadmap__quartal--list li::before {
          right: 0;
        }
      }

      &:nth-child(even) {
        margin-top: 150px;

        .roadmap__quartal--circle {
          left: -15px;
        transform: translate(-50%, -50%);
        }

        .roadmap__quartal--list li::before {
          left: 0;
        }
      }
    }

    &--circle {
    @media only screen and (min-width: 992px) {

      position: absolute;
      top:50%;
      width: 70px;
      height: 70px;
      background-color: #003166;

      &::before, &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      } 

      &::before {
        width: 40px;
        height: 40px;
        border: 8px solid rgba(255, 197, 0, .2);
      }

      &::after {
        width: 10px;
        height: 10px;
        border: 6px solid rgba(255, 197, 0, 1);
      }
    }
    }

    &--name {
      position: relative;
      font-size: 32px;
      font-weight: 400;

      @media only screen and (min-width: 768px) {
        font-size: 36px;
      }
      
      span {
        font-weight: bold;
      }

      @media only screen and (min-width: 992px) {
          padding: 0 45px;
          font-size: 40px;
      }
    }

   

    &--item {
      display: inline-flex;
      align-items: flex-start;
    }

    &--itemType {
      margin-left: 12px;
      padding: 6px 8px;
      color: #fff;
      background-color: #ffc500;
      border-radius: 5px;
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      margin-top:3px;


    @media only screen and (min-width: 992px) {
      margin: 3px 20px;
    }

      &.-product {
        background-color: #008dce;
      }

      &.-project {
        background-color: #ffc500;
      }

      &.-marketing {
        background-color: #290E59;
      }
    }
    
    ul {
      li {
        text-indent: 0;
        list-style-type: none;
      }
    }

    &--list {
      font-size: 16px;
      padding: 0;
      color: rgba(255,255,255, .9);

      @media only screen and (min-width: 768px) {
        font-size: 18px;
      }
      
      @media only screen and (min-width: 992px) {
        padding: 0 45px;
       }

       li {
         position: relative;
         padding: 8px 20px;
         margin: 0 5px;

         &::before {
           content: '';
            position: absolute;
           width: 5px;
           height: 5px;
           top: 19px;
           background-color: #ffc500;
           border-radius: 50%;
              @media only screen and (max-width: 992px) {
                left: 0;
              }
          }

       }
    }
    &--innerList {
      padding: 0;
      margin-top: 5px;
      font-size: 14px;
      color: rgba(255,255,255, .7);

      li {
        padding-top: 3px;
        padding-bottom: 3px;        
        &::before {
           top: 17px;
           background-color: #008DCE;
        }
      }
    }
  }
}

`;

export default RoadmapInnerWrapper;
