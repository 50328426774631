import React from "react"
import { Container, Row, Col } from "reusecore/Layout"
import Heading from "reusecore/Heading"
import { SectionTitle } from "reusecore/SectionTitle"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Data from "./utility/data.js";

import RoadmapInnerWrapper from "./roadmap-inner.style"




const RoadmapInner = () => {

  return (
    <RoadmapInnerWrapper id="token">
      <Container>
        <SectionTitle>
          <Heading>
            Roadmap
          </Heading>
          {/* <Text>
                  BETA IS LIVE ON Testnet. Become a beta tester and get whitelisted for Airdrop.
                </Text> */}
        </SectionTitle>
        <Tabs>
          <TabList>
            {
              Data.map((item, k) => (
                <Tab key={k}>
                  <div>
                    {item.year}
                  </div>
                </Tab>
              ))
            }
          </TabList>
          <div className="roadmap__container">
            {
              Data.map((year, key) => (
                <TabPanel key={key}>
                  <h2 className="roadmap__panel-title">{year.year}</h2>
                  <Row>
                    {
                      year.quartals.map((quartal, x) => (
                        <Col key={x} className="col-12 lg-6 roadmap__quartal">
                          <h3 className="roadmap__quartal--name"><span className="roadmap__quartal--circle"></span><span>{quartal.name}</span> {year.year}</h3>
                          <ul className="roadmap__quartal--list">
                            {
                              quartal.list.map((item, xx) => (
                                <li key={xx}>
                                  <span className="roadmap__quartal--item">
                                    <span className="roadmap__quartal--itemName">
                                      {item.name}
                                    </span>
                                    {
                                      item.type &&
                                      <span className={`roadmap__quartal--itemType ${item.type === 'Project' ? '-project' : ''} ${item.type === 'Product' ? '-product' : ''} ${item.type === 'Marketing' ? '-marketing' : ''}`}>{item.type}</span>
                                    }
                                  </span>
                                  {
                                    item.innerList &&
                                    <ul className="roadmap__quartal--innerList">
                                      {
                                        item.innerList.map((item, xxx) => (
                                          <li key={xxx}>{item}</li>
                                        ))
                                      }
                                    </ul>
                                  }
                                </li>

                              ))
                            }
                          </ul>
                        </Col>
                      ))
                    }
                  </Row>
                </TabPanel>
              ))
            }
          </div>
        </Tabs>
        {/* <Row>
          <Col className="lg-6 md-12 ">

          </Col>
          <Col className="lg-6 md-12 countdown-wrap">

          </Col>
        </Row> */}
      </Container>
    </RoadmapInnerWrapper>
  )
}

export default RoadmapInner
