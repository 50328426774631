const Data = [
  {
    year: '2019',
    quartals: [
      {
        name: 'Q2',
        list: [
          {
            name: 'Inception',
            type: 'Project'
          },
          {
            name: 'Research',
            type: 'Project'
          },
          {
            name: 'Inception',
            type: 'Project'
          },
          {
            name: 'EU public tender received',
            type: 'Project'
          },
        ]
      },
      {
        name: 'Q3 - Q4',
        list: [
          {
            name: 'Core concepts',
            type: 'Product'
          },
          {
            name: 'Core user stories',
            type: 'Product'
          },
          {
            name: 'Functional requirements',
            type: 'Product'
          },
          {
            name: 'Technical specifications',
            type: 'Product'
          },
          {
            name: 'Platform architecture',
            type: 'Product'
          },
          {
            name: 'Wireframes',
            type: 'Product'
          },
          {
            name: 'UX/UI',
            type: 'Product'
          },
          {
            name: 'Development started',
            type: 'Product'
          },
        ]
      },
    ]
  },
  {
    year: '2020',
    quartals: [
      {
        name: 'Q1 - Q2',
        list: [
          {
            name: 'Registration/Activation/Login/Forgot Password/Activation',
          },
          {
            name: 'Create Role (Freelancer, Client, Auditor, Educational Institution)',
          },
          {
            name: 'Basic profile editing',
          },
          {
            name: 'Creating/Editing a Project / Task',
          },
          {
            name: 'Submitting a Task proposal',
          },
          {
            name: 'Negotiations (Time, Price)',
          },
          {
            name: 'Accepting a Task proposal',
          },
          {
            name: 'Submitting Task results (uploading documents)',
          },
          {
            name: 'Basic chat system',
          },
          {
            name: 'Public browse views; Freelancers, Clients, Auditors, Educational Institutions',
          },
          {
            name: 'Browse filtering',
          },
        ]
      },
      {
        name: 'Q3 - Q4',
        list: [
          {
            name: 'Review freelancers work',
          },
          {
            name: 'Email & Real-time notifications',
          },
          {
            name: 'Inviting multiple freelancers to a project',
          },
          {
            name: 'Profile Setup Wizard',
          },
          {
            name: 'Documents viewing and download',
          },
          {
            name: 'Notifications settings',
          },
          {
            name: 'Milestones implementation',
          },
          {
            name: 'Recurring tasks',
          },
          {
            name: 'GDPR requirements implementation',
          },
          {
            name: 'Blockchain research',
          },
          {
            name: 'Monetization research (platform costs, earnings)',
          },
          {
            name: 'Gamification research',
          },
          {
            name: 'Platform security risks research',
          },
          {
            name: 'Audit process research',
          },
        ]
      },
    ]
  },
  {
    year: '2021',
    quartals: [
      {
        name: 'Q1',
        list: [
          {
            name: 'Flexible Competencies/Skills model',
          },
          {
            name: 'Multiple Skills issuers support',
          },
          {
            name: 'Peaceful conflict resolution added to Audit process',
          },
          {
            name: 'Gamification model',
          },
          {
            name: 'Subscription model',
          },
        ]
      },
      {
        name: 'Q2 - Q3',
        list: [
          {
            name: 'Blockchain integration',
          },
          {
            name: 'Third-party crypto payment provider',
          },
          {
            name: 'Competencies/Skills model upgrade',
          },
          {
            name: 'User wallet page',
          },
          {
            name: 'Transaction history with Invoices for clients',
          },
          {
            name: 'Switching to Binance Smart Chain',
          },
        ]
      },
      {
        name: 'Q4',
        list: [
          {
            name: 'Blockchain testing',
          },
          {
            name: 'Platform stress testing',
          },
          {
            name: 'Performance & SEO optimizations',
          },
          {
            name: 'Data security policy',
          },
          {
            name: 'Platform documentation',
          },
          {
            name: 'Testing groups',
          },
          {
            name: 'EXPO2020 DUBAI presentation',
            type: 'Marketing'
          },
          {
            name: 'Polygon Matic Blockchain research',
          },
        ]
      },
    ]
  },
  {
    year: '2022',
    quartals: [
      {
        name: 'Q1',
        list: [
          {
            name: 'Beta launch',
            type: 'Product',
            innerList: [
              'Beta testers reward program',
            ]
          },
          {
            name: 'New UX/UI',
            type: 'Product',
            innerList: [
              'Simplified user onboarding',
              'Simplified job creation'
            ]
          },
          {
            name: 'Polygon L2 chain',
            type: 'Product',
            innerList: [
              'Gasless transactions',
              'Gasless Skills (NFT) minting'
            ]
          },
          {
            name: 'Polygon Developer Support Program',
            type: 'Marketing',
          },
          {
            name: 'EXPO2020 DUBAI Investors/Contributors event',
            type: 'Marketing',
          },
        ]
      },
      {
        name: 'Q2',
        list: [
          // {
          //   name: 'WNS Token Pre-sale',
          //   type: 'Project',
          // },
          {
            name: 'Skills redesign',
            type: 'Project',
            innerList: [
              'ESCO standard',
              'IPFS metadata'
            ]
          },
          {
            name: 'Subscription plans',
            type: 'Product',
          },
          {
            name: 'Gamification',
            type: 'Product',
          },
        ]
      },
      {
        name: 'Q4',
        list: [
          {
            name: 'LAUNCH TestNet',
            type: 'Product',
          },
          {
            name: 'WNS Token AirDrop',
            type: 'Project',
          },
          // {
          //   name: 'WNS Token ICO',
          //   type: 'Product',
          // },
          // {
          //   name: 'Listings on exchanges',
          //   type: 'Marketing',
          // },
          // {
          //   name: 'Referral system',
          //   type: 'Product',
          // },
          {
            name: 'FIAT Ramp off',
            type: 'Product',
          },
        ]
      },
    ]
  },
  {
    year: '2023',
    quartals: [
      {
        name: 'Q1',
        list: [
          {
            name: 'WNS Token AirDrop',
            type: 'Project',
          },
          {
            name: 'GOING LIVE',
            type: 'Product',
          },
          // {
          //   name: 'Skills migration to new eIdentity',
          //   type: 'Product',
          // },
          // {
          //   name: 'AI for suggesting missing skills with the highest impact for the user',
          //   type: 'Product',
          // },
          // {
          //   name: 'AI for searching suitable tasks for a freelancer',
          //   type: 'Product',
          // },
          // {
          //   name: 'DAO governance',
          //   type: 'Project',
          // },
        ]
      },
    ]
  },
]
export default Data
