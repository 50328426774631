import React from "react";
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo";
import Navigation from "sections/Navigation";
import Footer from "sections/Footer";

import theme from "assets/theme/theme";
import GlobalStyle from "assets/theme";
import RoadmapInner from "../sections/Roadmap-inner";

const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Roadmap" />
      <Navigation />
      <RoadmapInner />
      <Footer />
    </Layout>
  </ThemeProvider>
)

export default IndexPage
